import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
//-----COMPONENTS-----
import { VerticalText, Link } from "./globals";
import DropdownMenu from "./dropdownMenu";
//-----ASSETS-----
import Logo from "../assets/smith-built-logo.svg";
//--------------MATERIAL--------------
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const Header = styled(Box)`
  height: 100vh;
  width: 5em;
  position: fixed;
  padding-top: 2.5em;
  z-index: 10;
  background-color: #fff;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      padding-top: 4.5em;
      width: 8.75em;
    }
  `}
`;

const Img = styled.img`
  width: 50%;
  margin: 0 auto;
`;

function SiteTitle(props) {
  return (
    <Grid container item justify="center">
      <VerticalText item="true" component="span">
        <Typography variant="h2">{props.children}</Typography>
      </VerticalText>
    </Grid>
  );
}

function SiteLogo() {
  return (
    <Grid container item>
      <Img src={Logo} />
    </Grid>
  );
}

export default () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Header component="header">
      <Grid container direction="column" spacing={3} component={Link} to="/">
        <SiteTitle>{data.site.siteMetadata.title}</SiteTitle>
        <SiteLogo />
      </Grid>
      <DropdownMenu />
    </Header>
  );
};
