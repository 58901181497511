import React from "react";
import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, MarginLeft } from "./globals";
import Header from "./header";
import Footer from "./footer";
import theme from "./theme";
//--------MATERIAL-------------
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";

const Main = styled.main`
  position: relative;
  //overflow: hidden;
`;

export const DropdownContext = React.createContext();

export default ({ children }) => {
  const [contactUsMenuOpen, setContactUsMenuOpen] = React.useState(false);
  const [browseMenuOpen, setBrowseMenuOpen] = React.useState(false);

  const menuToggles = {
    browseMenuOpen,
    contactUsMenuOpen,
    toggleContactMenu: () => {
      if (browseMenuOpen) {
        setBrowseMenuOpen(false);
        setTimeout(() => setContactUsMenuOpen(true), 250);
      } else {
        setContactUsMenuOpen(!contactUsMenuOpen);
      }
    },
    toggleBrowseMenu: () => {
      if (contactUsMenuOpen) {
        setContactUsMenuOpen(false);
        setTimeout(() => setBrowseMenuOpen(true), 250);
      } else {
        setBrowseMenuOpen(!browseMenuOpen);
      }
    },
  };

  return (
    <StylesProvider>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/mjf5ibc.css" />
          </Helmet>

          <GlobalStyle />

          <DropdownContext.Provider value={menuToggles}>
            <Header menuToggles={menuToggles} />

            <MarginLeft>
              <Main>{children}</Main>

              <Footer />
            </MarginLeft>
          </DropdownContext.Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
