import React from "react";
import styled from "styled-components";
import JltechMark from "./footerJltechMark";
import { Link } from "./globals";
import { Container, Header } from "./footer";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import bbbSeal from "../assets/AB-seal-horz.svg";

const GridMargins = styled(Grid)`
  width: max-content;
  margin: 5px 0;
  margin-bottom: 0.75em;
  margin-right: 2.75em;
  @media (min-width: 1715px) {
    margin-right: 25px;
    margin-left: 25px;
  }
`;

const GridCenter = styled(Grid)`
  @media (min-width: 706px) {
    justify-content: center;
  }
`;

const BBBLink = styled(Link)`
  position: relative;
`;

const BBBSeal = styled.img`
  position: absolute;
  top: 0;
  right: -160%;
  width: 140%;
  height: 100%;
`;

function GatsbyLink(props) {
  return (
    <Link to={props.href} color="inherit" variant="caption">
      {props.children}
    </Link>
  );
}

function ExternalLink(props) {
  return (
    <BBBLink component="a" href={props.href} color="inherit" variant="caption">
      {props.children}
      <BBBSeal src={bbbSeal} alt="bbb accredited business" />
    </BBBLink>
  );
}

function SiteLinksContainer({ header, links }) {
  return (
    <GridMargins container item direction="column">
      <Grid item>
        <Header variant="h5">{header}</Header>
      </Grid>
      {links.map((link, index) => (
        <Grid item key={index}>
          {link.slug[0] === "/" ? (
            <GatsbyLink href={link.slug}>{link.label}</GatsbyLink>
          ) : (
            <ExternalLink href={link.slug}>{link.label}</ExternalLink>
          )}
        </Grid>
      ))}
    </GridMargins>
  );
}

function SiteLinks() {
  return (
    <Container container item>
      <GridCenter
        container
        item
        alignContent="center"
        component={Box}
        height="79.4%"
        py={3.5}
        pl={{ xs: 2.5 }}
      >
        <SiteLinksContainer
          header="Services"
          links={[
            { label: "new construction", slug: "/new-construction-services" },
            { label: "remodels", slug: "/remodels" },
            { label: "project management", slug: "/management" },
            { label: "additions", slug: "/additions" },
          ]}
        />

        <SiteLinksContainer
          header="Company"
          links={[
            { label: "blogs", slug: "/blogs" },
            {
              label: `BBB`,
              slug:
                "https://www.bbb.org/us/id/post-falls/profile/general-contractor/smithbuilt-1296-1000082243",
            },
          ]}
        />
        {/*<SiteLinksContainer*/}
        {/*  header="Legal"*/}
        {/*  links={[*/}
        {/*    { label: "privacy policy", slug: "/" },*/}
        {/*    { label: "terms of use", slug: "/" },*/}
        {/*  ]}*/}
        {/*/>*/}
      </GridCenter>

      <JltechMark>website designed & developed by http://jlte.ch</JltechMark>
    </Container>
  );
}

export default SiteLinks;
