import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";

const Form = styled.form`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-family: "Montserrat";
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.875rem;
        flex-flow: row nowrap;
    }
  `}
`;

const Label = styled.label``;

const StyledInput = styled(Input).attrs({
  required: true,
  disableUnderline: true,
})`
  padding-left: 0.5em;
  font-family: "Montserrat";
  font-size: 1rem;
  opacity: 50%;
  color: #fff;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.5rem;
        line-height: 37px;
    }
  `}
`;

const VerticalBtn = styled(Box)`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 13%;
  min-width: 53px;
  max-width: 83px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
`;

const SendInput = styled(Input)`
  font-family: "Montserrat";
  font-size: 1rem;
  transform: rotate(270deg);
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.7rem;
    }
  `}
`;

function SubscribeForm() {
  return (
    <Form action="">
      <Label htmlFor="email">email: </Label>
      <StyledInput type="email" name="email" id="email" multiline />

      <VerticalBtn>
        <SendInput type="submit" value="subscribe" />
      </VerticalBtn>
    </Form>
  );
}

export default SubscribeForm;
