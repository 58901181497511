import React from "react";
import styled from "styled-components";
//-----ASSETS-----
import MailIcon from "../assets/material-mail-outline.svg";
import HamburgerIcon from "../assets/ionic-ios-menu.svg";
//--------------MATERIAL--------------
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const DropdownHeaderGrid = styled(Grid)`
  display: none;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        display: block;
      }
  `};

  @media (max-height: 760px) {
    display: none;
  }
`;

const Icon = ({ icon, alt, onClick }) => (
  <Grid container item justify="center">
    <IconButton color="inherit" onClick={onClick}>
      <img src={icon} alt={alt} />
    </IconButton>
  </Grid>
);

const MenuHeader = ({ contact, browse }) => {
  return (
    <DropdownHeaderGrid item open={contact || browse}>
      <Box
        fontFamily="Montserrat, sans-serif"
        fontSize="2.5625rem"
        fontWeight="700"
        minWidth="5em"
        ml={5.7}
      >
        {contact ? "Let's Get In Touch!" : browse ? "Browse Around" : null}
      </Box>
    </DropdownHeaderGrid>
  );
};

export default ({ menuToggles }) => {
  return (
    <Grid
      container
      item
      py={3}
      direction="column"
      justify="space-between"
      alignContent="center"
      wrap="nowrap"
      component={Box}
      height="100%"
      bgcolor="#000"
    >
      <Icon
        icon={MailIcon}
        alt="contact-us-icon"
        onClick={() => menuToggles.toggleContactMenu()}
      />

      <MenuHeader
        contact={menuToggles.contactUsMenuOpen}
        browse={menuToggles.browseMenuOpen}
      />

      <Icon
        icon={HamburgerIcon}
        alt="about-us-icon"
        onClick={() => menuToggles.toggleBrowseMenu()}
      />
    </Grid>
  );
};
