import React from "react";
import styled from "styled-components";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";
//-----ASSETS-----
import TwitterLogo from "../assets/awesome-twitter-square.svg";
import FacebookLogo from "../assets/awesome-facebook-square.svg";
import InstagramLogo from "../assets/awesome-instagram.svg";

const SocialsContainer = styled(Grid)`
  margin: 0;
`;

const Logo = styled.img`
  width: 1.3em;
  margin-right: 0.8em;
  ${({ theme }) => `
      ${theme.breakpoints.up("md")} {
        width: 1.95em;
      }
    `}
`;

function SocialMediaLinks() {
  return (
    <SocialsContainer container>
      <Grid item>
        <a href="http://www.twitter.com">
          <Logo src={TwitterLogo} alt="twitter-logo" />
        </a>
      </Grid>
      <Grid item>
        <a href="http://www.instagram.com">
          <Logo src={InstagramLogo} alt="instagram-logo" />
        </a>
      </Grid>
      <Grid item>
        <a href="http://www.facebook.com">
          <Logo src={FacebookLogo} alt="facebook-logo" />
        </a>
      </Grid>
    </SocialsContainer>
  );
}

export default SocialMediaLinks;
