import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { DropdownContainer, StyledMarginLeft, TileLabel } from "./dropdownMenu";
import { Link } from "./globals";
//--------------MATERIAL--------------
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridContainer = styled(Grid)`
  width: 100%;
  font-size: 1rem;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      font-size: 1.875rem;
      width: 78.125%;
      flex-direction: column;
    }
  `};
`;

const DropdownSquare = styled(Grid).attrs({
  justify: "center",
  alignItems: "center",
})`
  width: 50%;
  text-align: center;
  padding: 0 1em;
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    :nth-child(1), :nth-child(4), :nth-child(5) {
    background-color: ${theme.palette.primary.main};
  }
  `};
`;

const BrowseTile = styled(DropdownSquare)`
  height: 33.333%;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      height: 50%;
      width: 33.333%
    }
  `};

  & > Link {
    width: 100%;
    height: 100%;
  }
`;

function MenuItem({ to, children, $services, ...other }) {
  return (
    <BrowseTile container item {...other}>
      <StyledLink to={to || "/"}>
        <TileLabel item $services={$services}>
          {children}
        </TileLabel>
      </StyledLink>
    </BrowseTile>
  );
}

function ServicesMenu() {
  return (
    <>
      <MenuItem to="/new-construction-services">new construction</MenuItem>
      <MenuItem to="/remodels/">remodels</MenuItem>
      <MenuItem to="/management/">project management</MenuItem>
      <MenuItem to="/additions/">additions</MenuItem>
    </>
  );
}

function DefaultBrowseMenu() {
  return (
    <>
      <MenuItem to="/blogs">blog</MenuItem>
      <MenuItem></MenuItem>
      <MenuItem></MenuItem>
      <MenuItem></MenuItem>
    </>
  );
}

export default ({ open }) => {
  const [servicesExpanded, setServicesExpanded] = React.useState(false);

  const toggleServices = () => {
    setServicesExpanded(!servicesExpanded);
  };

  return (
    <DropdownContainer open={open}>
      <StyledMarginLeft>
        <GridContainer
          container
          component={Box}
          fontFamily="Montserrat, sans-serif"
          height="100%"
        >
          <BrowseTile container item onClick={toggleServices}>
            <TileLabel item>services</TileLabel>
          </BrowseTile>
          <MenuItem $services={servicesExpanded}>about us</MenuItem>
          {servicesExpanded ? <ServicesMenu /> : <DefaultBrowseMenu />}
        </GridContainer>
      </StyledMarginLeft>
    </DropdownContainer>
  );
};
