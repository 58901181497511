import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { MarginLeft } from "./globals";
import { DropdownContext } from "./layout";
import BrowseMenu from "./dropdownBrowseMenu";
import ContactMenu from "./dropdownContactMenu";
import IconsNav from "./dropdownIconNav";
//--------------MATERIAL--------------
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const DropdownContainer = styled(Grid)`
  display: flex;
  width: 100%;
  height: 40.6vh;
  position: fixed;
  z-index: -1;
  bottom: 0;
  overflow-x: hidden;
  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 250ms ease-in-out;
  transform-origin: left;
  @media (min-width: 960px) {
    height: 33.7vh;
  }
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main}
  `};
`;

const DropdownMenuGrid = styled(Grid)`
  height: 40.6vh;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        height: 33.7vh;
      }
    `};
`;

const DropdownTile = styled(Grid).attrs({
  justify: "center",
  alignItems: "center",
})`
  width: 50%;
  text-align: center;
  padding: 0 1em;
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    :nth-child(1), :nth-child(4), :nth-child(5) {
    background-color: ${theme.palette.primary.main};
  }
  `};
`;

const TileLabel = styled(Grid)`
  cursor: pointer;
  text-decoration: none;
  opacity: ${(props) => (props.$services ? "25%" : "100%")};
  ${({ theme }) => `
    color: ${theme.palette.primary.contrastText};
  `};
`;

const StyledMarginLeft = styled(MarginLeft)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default () => {
  return (
    <DropdownMenuGrid
      container
      component={Box}
      position="fixed"
      zIndex="1"
      bottom="0"
      left="0"
      bgcolor="primary.main"
      color="primary.contrastText"
      width="inherit"
    >
      <DropdownContext.Consumer>
        {(menuToggles) => (
          <>
            <IconsNav menuToggles={menuToggles} />
            <ContactMenu open={menuToggles.contactUsMenuOpen} />
            <BrowseMenu open={menuToggles.browseMenuOpen} />
          </>
        )}
      </DropdownContext.Consumer>
    </DropdownMenuGrid>
  );
};

export { DropdownContainer, DropdownTile, StyledMarginLeft, TileLabel };
