import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { DropdownContainer, StyledMarginLeft, TileLabel } from "./dropdownMenu";
import ContactForm from "./contactUsForm";
//--------------MATERIAL--------------
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const ContactGridContainer = styled(Grid)`
  width: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: 78.125%;
    }
    ${theme.breakpoints.up("lg")} {
      width: 50%;
    }
  `};
`;

const DropdownTile = styled(Grid).attrs({
  container: true,
  justify: "center",
  alignItems: "center",
})`
  width: 50%;
  text-align: center;
  padding: 0 1em;
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    :nth-child(1), :nth-child(4), :nth-child(5) {
    background-color: ${theme.palette.primary.main};
  }
  `};
`;

const MenuTile = styled(DropdownTile).attrs({ alignItems: "center" })`
  height: 50%;
  text-decoration: none;
`;

function MenuItem({ children, ...other }) {
  return (
    <MenuTile container item {...other}>
      <TileLabel item>{children}</TileLabel>
    </MenuTile>
  );
}

function DefaultMenu({ openForm }) {
  return (
    <>
      <MenuItem />
      <MenuItem onClick={openForm}>email form</MenuItem>
      <MenuItem component="a" href="tel:+1-208-964-7540">
        (208) 964 7540
      </MenuItem>
      <MenuItem />
    </>
  );
}

export default ({ open }) => {
  const [formOpen, toggleFormOpen] = React.useState(false);
  const openForm = () => toggleFormOpen(!formOpen);
  return (
    <DropdownContainer open={open}>
      <StyledMarginLeft>
        <ContactGridContainer
          container
          component={Box}
          height="100%"
          fontSize="30px"
          fontFamily="Montserrat, sans-serif"
        >
          {formOpen ? <ContactForm /> : <DefaultMenu openForm={openForm} />}
        </ContactGridContainer>
      </StyledMarginLeft>
    </DropdownContainer>
  );
};
