import React from "react";
import styled from "styled-components";
//-----MATERIAL-----//
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  font-size: 1rem;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.875rem;
        flex-flow: row nowrap;
    }
  `}
`;

const InputContainer = styled(Box)`
  flex: 0.5;
  padding-left: 10.6%;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      padding-left: 1.25em;
      flex: 1;
    }
  `}
`;

const SingleLineContainer = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  position: relative;
  padding-top: 1em;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      flex: 1 1 36%;
    }
  `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    min-width: 4.5%;
    height: 100%;
    ${({ theme }) => `
      background-color: ${theme.palette.secondary.main};
      ${theme.breakpoints.up("md")} {
        min-width: 10%;
        left: -10%;
      }
    `}
  }
`;

const SingleLineInput = styled(InputContainer)`
  flex: 1;
`;

const MultiLineInput = styled(InputContainer)`
  flex: 1;
  padding-top: 1em;
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    ${theme.breakpoints.up("md")} {
      flex: 1 1 52%;
    }
  `}
`;

const StyledInput = styled(Input).attrs({
  required: true,
  disableUnderline: true,
})`
  font-family: "Montserrat";
  font-size: 1rem;
  opacity: 50%;
  color: #fff;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.5rem;
        line-height: 37px;
    }
  `}
`;

const SendInput = styled(Input)`
  min-height: 50px;
  font-family: "Montserrat";
  font-size: 1rem;
  color: #fff;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
        font-size: 1.875rem;
        transform: rotate(270deg);
    }
  `}
`;

const VerticalBtn = styled(Box)`
  width: 13%;
  display: flex;
  justify-content: center;
`;

function SendBtn() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return matches ? (
    <VerticalBtn>
      <SendInput type="submit" value="send message" />
    </VerticalBtn>
  ) : (
    <InputContainer height="20%" display="flex" alignItems="center">
      <SendInput type="submit" value="send message" />
    </InputContainer>
  );
}

function ContactUsForm() {
  return (
    <Form>
      <SingleLineContainer>
        <SingleLineInput>
          <label htmlFor="name"> name: </label>
          <StyledInput name="name" id="name" multiline />
        </SingleLineInput>

        <SingleLineInput>
          <label htmlFor="subject"> subject: </label>
          <StyledInput name="subject" id="subject" multiline />
        </SingleLineInput>
      </SingleLineContainer>

      <MultiLineInput>
        <label htmlFor="message"> message: </label>
        <StyledInput name="message" id="message" multiline />
      </MultiLineInput>

      <SendBtn />
    </Form>
  );
}

export default ContactUsForm;
