import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
//-----MATERIAL-----
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link as MuiLink } from "@material-ui/core";

const Link = React.forwardRef(function Link(props, ref) {
  return (
    <MuiLink component={GatsbyLink} ref={ref} underline="none" {...props} />
  );
});

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body {
    overflow-x: hidden;
  }
  a, button {
    cursor: pointer;
  }
`;

const MarginLeft = styled(Grid)`
  margin-left: 5em;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      margin-left: 8.75em;
    }
  `}
`;

const Container = styled(Grid)`
  position: relative;
  width: 100%;
  @media (min-width: 960px) {
    width: 50%;
  }
`;

const VerticalText = styled(Typography)`
  height: ${(props) => props.height};
  writing-mode: vertical-lr;
  transform: rotate(180deg);
`;

const WidthBreakGrid = styled(Grid).attrs({
  component: "section",
})`
  position: relative;
  width: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: 50%;
    }
  `}
`;

const MainBreakpointGrid = styled(WidthBreakGrid)`
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      height: 100vh;
    }
  `}
`;

const PaddingBox = styled(Box)`
  padding: 0 2.5em;
`;

const VerticalDivider = styled(Box)`
  min-height: 51px;
  border-left: 2px solid #000;
`;

export {
  GlobalStyle,
  MarginLeft,
  Container,
  VerticalText,
  Link,
  WidthBreakGrid,
  MainBreakpointGrid,
  PaddingBox,
  VerticalDivider,
};
