import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#1d1d1d",
    },
  },
  typography: {
    fontFamily: ["montserrat, sans-serif", "essonnes-display, serif"].join(
      ", "
    ),
    h2: {
      fontSize: "1.25rem",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "1.875rem",
      lineHeight: "37px",
      fontWeight: "700",
      textTransform: "capitalize",
      "@media (min-width: 600px)": {},
    },
    h4: {
      fontSize: "1.3125rem",
      lineHeight: "25px",
      fontWeight: "700",
      textTransform: "capitalize",
      "@media (min-width: 600px)": {
        fontSize: "1.875rem",
        lineHeight: "37px",
      },
    },
    h5: {
      fontSize: "1.125rem",
      lineHeight: "22px",
      fontWeight: "700",
      textTransform: "capitalize",
      "@media (min-width: 600px)": {
        fontSize: "1.875rem",
        lineHeight: "37px",
      },
    },
    subtitle1: {},
    subtitle2: {},
    button: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "18px",
      textTransform: "lowercase",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "15px",
      textTransform: "lowercase",
      "@media (min-width: 600px)": {
        fontSize: "1.5rem",
        lineHeight: "29px",
      },
    },
    body1: {
      fontFamily: "essonnes-display",
      fontSize: "0.875rem",
      lineHeight: "17px",
      "@media (min-width: 600px)": {
        fontSize: "1rem",
        lineHeight: "19px",
      },
    },
    body2: {
      fontFamily: "essonnes-display",
      fontSize: "1rem",
      lineHeight: "19px",
    },
  },
});

export default theme;
