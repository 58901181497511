import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import InternalLinks from "./footerSiteLinks";
import SocialMediaLinks from "./footerSocialMedia";
import SubscribeForm from "./footerSubscribeForm";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Footer = styled(Grid).attrs({ component: "footer" })`
  position: relative;
  z-index: 1;
  min-height: 33.7vh;
  ${({ theme }) => `
    color: ${theme.palette.primary.contrastText};
    background-color: ${theme.palette.primary.main};
  `};
`;

const Container = styled(Grid)`
  width: 100%;
  min-height: 100%;
  position: relative;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: 50%;
    }
  `}
`;

const Header = styled(Typography)`
  margin-bottom: 0.5em;
`;

const HeaderWithChild = ({ header, bgcolor = "inherit", children }) => (
  <Grid
    container
    item
    alignContent="center"
    component={Box}
    position="relative"
    minHeight="7.8em"
    bgcolor={bgcolor}
    height="50%"
    py={{ xs: 2, md: 4 }}
    pl={{ xs: 2.5, md: 7 }}
    pr="83px"
  >
    <Grid item>
      <Header variant="h5">{header}</Header>
    </Grid>
    <Grid container item>
      {children}
    </Grid>
  </Grid>
);

export default () => {
  return (
    <Footer container>
      <Container>
        <HeaderWithChild header="see what's new" bgcolor="secondary.main">
          <SocialMediaLinks />
        </HeaderWithChild>
        <HeaderWithChild header="subscribe and keep in touch">
          <SubscribeForm />
        </HeaderWithChild>
      </Container>
      <InternalLinks />
    </Footer>
  );
};

export { Container, Header };
