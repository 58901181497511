import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const JltechLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

function JltechMark(props) {
  return (
    <Grid
      container
      item
      component={Box}
      bgcolor="secondary.main"
      height="20.6%"
    >
      <Grid
        container
        item
        justify="center"
        alignContent="center"
        component={Box}
        px={1}
      >
        <Typography variant="caption" align="center">
          <JltechLink href="http://jlte.ch">{props.children}</JltechLink>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default JltechMark;
